import { get, post } from "../utils/httprequest"; // 导入模块
//客户
//客户列表
export const customerListAPI = (params) => post("/customer/list", params);
//客户下拉框数据
export const selectcustomerApi = (params) => get("/customer/selectBox", params);
//客户详情头部基本信息
export const customerBaseAPI = (params) => get("/customer/get-baseInfo",params);
//客户详情-详情页
export const customerDetailAPI = (params) => get("/customer/get-detailInfo",params);
//客户详情-编辑页
export const customerDetailEditAPI = (params) => get("/customer/get-detailInfo/on-edit",params);
//终止合作
export const customerStopAPI = (params) => post("/customer/stop-cooperation", params);
//新增客户
export const addCustomerAPI = (params) => post("/customer/add", params);
//编辑客户
export const updateCustomerAPI = (params) => post("/customer/update", params);
//客户单个字段更新
export const updateFieldCustomerAPI = (params) => post("/customer/update/some-field", params);
//客户联系人
//客户联系人列表
export const contactListAPI = (params) => post("/customerContacts/pageList", params);
//客户联系人详情
export const contactDetailAPI = (params) => get("/customerContacts/detail", params);
//添加客户联系人
export const addContactAPI = (params) => post("/customerContacts/add", params);
//编辑客户联系人
export const updateContactAPI = (params) => post("/customerContacts/update", params);
//客户联系人负责的职位
export const contactProjectAPI = (params) => get("/customerContacts/projectList", params);
//客户联系人负责的职位
export const relatedPositionsAddAPI = (params) => post("/project/queryProjectSelectBoxOnAddCustomerContact", params);
//职位下拉框(添加客户联系人时)
export const relatedPositionsUpdateAPI = (params) => post("/project/queryProjectSelectBoxOnUpdateCustomerContact", params);
//职位下拉框(编辑客户联系人时)
//客户合同
//客户合同列表
export const contractListAPI = (params) => post("/customerContracts/pageList", params);
//合同详情
export const contractDetailAPI = (params) => get("/customerContracts/detail", params);
//添加合同
export const addContractAPI = (params) => post("/customerContracts/add", params);
//编辑合同
export const updateContractAPI = (params) => post("/customerContracts/update", params);
//客户左侧菜单
export const customerLeftMenuAPI = (params) => get("/customer/statistic", params);
//查询客户合同附件
export const getAttachmentListAPI = (params) => get("/customerContracts/getAttachment", params);
//添加备注
export const customerRemarkSaveApi = (params) => post("/customerRemark/add", params);
//编辑备注
export const customerRemarkUpdateApi = (params) => post(`/customerRemark/update`,params);
//删除备注
export const customerRemarkDelApi = (params) => post("/customerRemark/delete", params);
//备注列表
export const customerRemarkListApi = (params) => post("/customerRemark/pageList", params);
//客户详情-职位列表
export const customerProjectListApi = (params) => post("/project/customer/list", params);
//客户详情-删除职位
export const customerProjectDelApi = (projectId) => post(`/project/delete/${projectId}`);
//校验客户名称是否已存在
export const checkCustomerNameAPI = (params) => get("/customer/checkExistSameNameCustomer", params);