/*
 * @Author: liushuang
 * @Date: 2023-02-03 15:44:20
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\plugins\antd.js
 */
import { 
  ConfigProvider,
  Avatar, 
  Breadcrumb, 
  Button, 
  Checkbox, 
  Form, 
  Input, 
  Select,
  Layout,
  Table, 
  Popover,
  Menu, 
  Pagination, 
  Dropdown,
  DatePicker,
  Icon,
  TreeSelect,
  Tooltip,
  Modal,
  Upload ,
  Popconfirm,
  Radio,
  Switch,
  Tabs,
  Tree,
  Space,
  Tag ,
  Drawer,
  Row,
  Col,
  InputNumber,
  Divider,
  Spin,
  Progress,
} from 'ant-design-vue';
const components = [ConfigProvider, Button, Pagination, Form, Input, Select, Checkbox, Layout, Table, Popover, Menu, Breadcrumb, Avatar, Dropdown, DatePicker, Icon, TreeSelect, Tooltip, Modal ,Upload,Popconfirm,Radio,Switch,Tabs,Tree,Space,Tag, Drawer, Row, Col,InputNumber, Divider, Spin, Progress];
export const componentsPlugin = app => components.forEach(app.use, app);