/*
 * @Author: liushuang
 * @Date: 2023-03-10 11:39:02
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\composables\comm.js
 */
import { domain } from "@/utils/request";
//文件查看
export function setFileUrl(url){
  if ((url.indexOf(".doc") > 0 || url.indexOf(".docx") > 0) && url.indexOf("op/view") == -1) {
    return  "https://view.officeapps.live.com/op/view.aspx?src=" + url;
  } else if (url.indexOf(".pdf") > 0  && url.indexOf("view?") == -1) {
    return `${domain.Base_M2_URL}/tool/pdf/view/?target=` + url;
  } else {
    return url;
  }
}
//下载文件
export function linkPdf(fileName, url) {
  let name = encodeURIComponent(fileName);
  return `${domain.Base_M2_URL}/tool/file/download?target=${url}&fileName=${name}`;
}
//类型转换
export function setBoolean(val) {
  return val === 1 ? true : false;
}