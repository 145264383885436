<!--* 添加客户/录入客户外壳-->
<template>
  <div  ref='model'>
    <a-modal 
      :getContainer = '()=>$refs.model'
      :visible="isShowMoadl" 
      :maskClosable=false 
      :keyboard=false 
      width="80%"
      wrap-class-name="addModel" 
      :footer="null"
      centered
      :zIndex="2001"
      :maskStyle="{'z-index': '2000'}"
    >
      <slot></slot>
      <template #closeIcon>
      </template>
    </a-modal>
  </div>
  
</template>
<script>
import { defineComponent} from 'vue';
// import { useRouter } from 'vue-router';
export default defineComponent({
  // emits: ['closeMoadl'],
  // props:['isShowMoadl','title','path'],
  props:['isShowMoadl'],
  // setup(props,{ emit }) {
  setup() {
    // var data = reactive({
    //   // isMax:false,//是否最大化
    //   router :useRouter(),
    // })
    // const minOperation = ()=>{
    //   emit('closeMoadl','isShowMoadl',false)
    // };//最小化操作
    // const maxOperation = ()=>{
    //   const url = data.router.resolve({
    //     path: props.path,
    //   });
    //   // 打开新窗口
    //   window.open(url.href);
    //   emit('closeMoadl','isShowMoadl',false)
    // };//最大化操作
    // const closeMoadl=()=>{
    //   emit('closeMoadl','isShowMoadl',false)
    // }
    return {
      // ...toRefs(data),
      // minOperation,
      // maxOperation,
      // closeMoadl
    };
  },
});
</script>
<style scoped>
/* 修改弹窗公共样式 */
::v-deep .addModel  .ant-modal-close-x{
  width: 100%;
  display: flex;
  align-items: center;
}
::v-deep .addModel .ant-modal-close-x .icon{
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: content-box;
}
::v-deep .ant-modal-content .ant-modal-close{
  right:14px!important;
}
::v-deep .addModel .ant-modal-close,
::v-deep .addModel .ant-modal-close:focus, 
::v-deep .addModel .ant-modal-close:hover{
  color: rgba(0, 0, 0, 0.85);
}
::v-deep .center-title span svg:hover path{
  fill: #507AFD!important
}
</style>
