/*
 * @Author: liushuang
 * @Date: 2023-02-03 14:06:40
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\utils\storage.js
 */
export const setToken=(token)=>localStorage.setItem('TOKEN',token)

export const setUser=(user)=>localStorage.setItem('USER',user)

export const setTime=(time)=>localStorage.setItem('TIME',time)


//存储数据
export const setItem = (key, data) => {
  if (typeof data === 'object') {
    window.sessionStorage.setItem(key, JSON.stringify(data));
  } else {
    window.sessionStorage.setItem(key, data);
  }
}

//读取数据
export const grtItem = (key) => {
  const item = window.sessionStorage.getItem(key);   try{
    
    const value = JSON.parse(item);
    if (typeof value === 'object') {
      return value;
    } else {
      return item;
    }
  } catch (err) {
    return item;
  }
} 

//移除数据
export const removeItem = (key) => {
  window.sessionStorage.removeItem(key);
}
