/*
 * @Author: liushuang
 * @Date: 2023-02-13 15:10:27
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\utils\validater.js
 */
export const validater = {
  isEmpty: function(obj) { //校验是否为空
    if (typeof obj == undefined || obj == null || obj === "" || obj == "null") {
      return true;
    } else {
      return false;
    }
  },
  min: function(num, min) { //数小于最小数
    if (parseInt(num) < parseInt(min)) {
      return false
    } else {
      return true
    }
  },
  minAnd: function(num, min) { //数小于等于最小数
    if (parseInt(num) <= parseInt(min)) {
      return false
    } else {
      return true
    }
  },
  max: function(num, max) { //数大于最大数
    if (parseInt(num) > parseInt(max)) {
      return false
    } else {
      return true
    }
  },
  maxAnd: function(num, max) { //数大于等于最大数
    if (parseInt(num) >= parseInt(max)) {
      return false
    } else {
      return true
    }
  },
  towDecimal: function(num) { //最多输入2位小数
    var reg = /^(-?\d+)(\.\d{1,2})?$/;
    if (!reg.test(num)) {
      return false;
    } else {
      return true;
    }
  },
  phoneVal: function(val) { //验证手机号
    var reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/;
    if (reg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  emailVal: function(val) { //验证邮箱
    var reg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (reg.test(val)) {
      return true;
    } else {
      return false;
    }
  },
  toArrSort: function(arr) {
    if(arr.length > 0) {
      return JSON.stringify(arr.sort())
    } else {
      return arr;
    } 
  },
  valZheng: function(num) { //文本框输入正整数和0的验证
    let val = num.replace(/^\.+|[^\d]+/g, '')
    if (val > 0) {
      if (val[0] == 0) {
        val = val.substr(1);
      }
    } else if (val == 0) {
      val = val.slice(0, 1);
    }
    return val
  },
  checkNum2: function(val) { //输入数字且只能输入二位小数
    let val1 = val.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符 
    val1 = val1.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的   
    val1 = val1.replace(".","$#$").replace(/\./g,"").replace("$#$",".");  
    val1 = val1.replace(/^(!-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数   
    if(val1.indexOf(".")< 0 && val1 !=""){ //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
      val1 = parseFloat(val1);  
    }
    return val1
  },
  strIndexOf: function(val, str) {
    var texts = str;  //替换之后的字符串
    // 获取要查询的正则（全局匹配不区分大小写）
    var allVal = str.match(new RegExp(val, 'ig'));
    // 当正则匹配的字符串存在时
    if (allVal) {
      for (var j = 0; j < allVal.length; j++) {
        texts = texts.replace(allVal[j], '[*' + j + '*]');
      }
      for (var k = 0; k < allVal.length; k++) {
        texts = texts.replace('[*' + k + '*]', '<span style="color: #507AFD">' + allVal[k] + '</span>');
      }
    }
    return texts
  },
}