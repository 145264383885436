/*
 * @Author: liushuang
 * @Date: 2023-02-03 08:54:38
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\router\index.js
 */
import {
  createRouter,
  createWebHistory
} from 'vue-router'

const positionIndex = () => import('../views/position/index')
const positions = () => import('../views/position/positionList.vue') //职位列表
const AddPosition = () => import('../views/position/entryPosition.vue') //录入职位
const PositionDetails = () => import('../views/position/positionDetails.vue') //职位详情

const RecommendIndex = () => import('../views/recommend/index')
const recommends = () => import('../views/recommend/recommendList.vue') //推荐列表
const setIndex = () => import('../views/set/index')//设置
const custormSet = () => import('../views/set/custormSet.vue') //客户设置
const set = () => import('../views/set/set.vue') //职类设置
const PositionSet = () => import('../views/set/positionSet.vue') //设置职位
const LogoSet = () => import('../views/set/logoSet.vue') //设置logo
const personnelSet = () => import('../views/set/personnelSet.vue') //简历设置
const companyList =()=> import('../views/set/company/companyList.vue') //公司列表
const userList=()=>import('../views/set/user/userList.vue')//用户列表

const custormIndex = () => import('../views/custorm/index')//客户列表
const customers = () => import('../views/custorm/custormList.vue')

const personnelIndex = () => import('../views/personnel/index')//简历列表
const personnels = () => import('../views/personnel/personnelList.vue')

const homePage = () => import('../views/homePage.vue') //首页
const achievementIndex = () => import('../views/achievement/index') //绩效
const achievementList = () => import('../views/achievement/achievementList.vue') //绩效列表
const achievementCheckList = () => import('../views/achievement/achievementCheckList.vue') //绩效审批

const statisticIndex = ()=>import("../views/statistic/index")//统计模块
const operateList = ()=>import("../views/statistic/operate/operateList.vue")//统计模块

const routes = [
  { 
    path: '/',
    redirect: '/homePage'
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录', isHidden: true },
    component: () => import('../views/login.vue'),
  },
  {
    path: '/index',
    component: () => import('../views/index.vue'),
    children: [
      { //首页
        path: '/homePage',
        name: 'homePage',
        component: homePage
      },
      { //职位模块
        path: '/positionIndex',
        name: 'position',
        component: positionIndex,
        redirect: "positions",
        children: [
          {
            path: '/positions',
            name: "positions",
            component: positions,
            meta: { title: '职位' },
          }
        ]
      }, {
        path: '/addPosition',
        name: "addPosition",
        component: AddPosition,
        meta: { title: '录入职位' },
      }, {
        path: '/positionDetails',
        name: "positionDetails",
        component: PositionDetails,
        meta: { title: '职位详情' },
      }, { //推荐模块
        path: '/RecommendIndex',
        name: 'recommend',
        component: RecommendIndex,
        redirect: "recommends",
        children: [
          {
            path: '/recommends',
            name: "recommends",
            component: recommends,
            meta: { title: '推荐' },
          }
        ]
      }, 
      // {
      //   path: '/recommendDetails',
      //   name: 'recommendDetails',
      //   component: () => import('../views/recommend/recommendDetails.vue'),
      // },
      { //设置模块
        path: '/setIndex',
        name: 'set',
        component: setIndex,
        redirect: "personnelSet",
        children: [
          {
            path: '/personnelSet',
            name: "personnelSet",
            component: personnelSet,
            meta: { title: '人才设置' },
          },
          {
            path: '/custormSet',
            name: "custormSet",
            component: custormSet,
            meta: { title: '客户设置' },
          }, {
            path: `/set/:type`,
            name: "set",
            component: set,
            meta: { title: '设置' },
          }, {
            path: '/positionSet',
            name: "positionSet",
            component: PositionSet,
            meta: { title: '设置职位' },
          }, {
            path: '/logoSet',
            name: "logoSet",
            component: LogoSet,
            meta: { title: '系统设置' }
          },{
            path: '/companyList',
            name: "companyList",
            component: companyList,
            meta: { title: '公司管理' },
          },{
            path: '/userList',
            name: "userList",
            component: userList,
            meta: { title: '用户列表' },
          },
          
        ]
      },
      { //客户模块
        path: '/custormIndex',
        name: 'customers',
        component: custormIndex,
        redirect: "customers",
        children: [
          {
            path: '/customers',
            name: "customers",
            component: customers,
            meta: { title: '客户' },
          }
        ]
      }, 
      { //简历模块
        path: '/personnelIndex',
        name: 'personnels',
        component: personnelIndex,
        redirect: "personnels",
        children: [
          {
            path: '/personnels',
            name: "personnels",
            component: personnels,
            meta: { title: '简历' },
          }
        ]
      },
      {
        path: '/addCustorm',
        name: 'addCustorm',
        component: () => import('../views/custorm/addCustormMax'),
      },//录入客户
      {
        path: '/custormDetails',
        name: 'custormDetails',
        component: () => import('../views/custorm/custormDetails'),
      },//客户详情页
      {
        path: '/addPersonnel',
        name: 'addPersonnel',
        component: () => import('../views/personnel/addPersonnelMax'),
      },//加入简历
      {
        path: '/personnelDetails',
        name: 'personnelDetails',
        component: () => import('../views/personnel/personnelDetails'),
      },//简历详情页
      {
        path: '/selectTalents',
        name: 'selectTalents',
        component: () => import('../components/selectTalents/selectTalents'),
      },//人才库
      { //设置模块
        path: '/statisticIndex',
        name: 'statistic',
        component: statisticIndex,
        redirect: "operateList",
        children: [
          {
            path: '/operateList',
            name: "operateList",
            component: operateList,
            meta: { title: '运营实况' },
          },
        ]
      },    
      {
        path: '/achievementIndex',
        name: 'achievement',
        component: achievementIndex,
        redirect: "achievementList",
        children: [
          { //绩效列表
            path: '/achievementList',
            name: 'achievementList',
            component: achievementList,
            meta: { title: '绩效列表' } 
          },
          { //绩效审批
            path: '/achievementCheckList',
            name: 'achievementCheckList',
            component: achievementCheckList,
            meta: { title: '绩效审批' } 
          }
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router