<!--
 * @Author: liushuang
 * @Date: 2023-02-07 15:05:19
 * @LastEditors: liushuang
 * @Description: 
 * @FilePath: \Ats-web\src\components\search\city.vue
-->
<template>
   <a-tree-select
    v-model:value="citySel"
    treeNodeFilterProp="title"
    style="width: 100%"
    tree-checkable
    placeholder="请选择"
    show-search
    labelInValue
    :showArrow="true"
    :filterTreeNode="filterTreeNode"
    :treeData="treeData"
    :dropdownMatchSelectWidth=false
    :getPopupContainer="
                triggerNode => {
                  return triggerNode.parentNode
                }"
    @change="searchBtn"
    @click="setNodeBtn"
    @search="searchValue"
  >
    <template #title="{ title }">
     <template v-if="title.toLowerCase().indexOf(selVal.toLowerCase()) > -1 && selVal.length > 0">
        <span v-html="validater.strIndexOf(selVal, title)"></span>
      </template>
      <span v-else>{{ title }}</span>
    </template>
    <template #suffixIcon><svg t="1675939229923" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2927" xmlns:xlink="http://www.w3.org/1999/xlink" width="12" height="12"><path d="M761.923087 702.208894c2.19403 1.535821 4.241792 3.217911 6.143285 5.192539l243.391091 243.317956A43.149262 43.149262 0 0 1 981.033577 1024a42.78359 42.78359 0 0 1-30.350752-12.505973L707.364868 768.029802a41.686575 41.686575 0 0 1-5.119404-6.143285 429.664261 429.664261 0 1 1 59.677623-59.750757z m-332.176182 71.305984a343.731408 343.731408 0 1 0 0-687.462817 343.731408 343.731408 0 0 0 0 687.462817z" fill="#A2A5B4" p-id="2928"></path></svg></template>
  </a-tree-select>
</template>
<script>
import { defineComponent, nextTick, reactive, ref, toRefs, onMounted,watch } from 'vue';
import { grtItem, setItem } from '@/utils/storage'
import {
  getCitylistApi
} from '../../api/comm.js'
import { message } from 'ant-design-vue';
import { validater } from "@/utils/validater.js"
import $ from 'jquery'
export default defineComponent({
  props: {
    oldSelData: Array,
    selectNum: Number
  },
  emits: ['searchCityhandle'],
  setup(props, {emit}) {
    const data = reactive({
      treeData: [],
      selVal: '',
    })
    const citySel = ref([]);
    citySel.value = props.oldSelData ? props.oldSelData : [];
    onMounted(() => {
      getCityAll()
    })
    watch(() => props.oldSelData, () => {
      if(props.oldSelData) {
        citySel.value = props.oldSelData
        getCityAll()
      }
    })
    //后台获取所有城市数据
    const getCityAll = () => {
      //判断是否本地存储有，有直接调用
      if (grtItem('cityArr')) {
        setCityTreeNode(grtItem('cityArr'));
      } else {
        getCitylistApi().then((res) => {
          if (res.success) {
            setCityTreeNode(res.data);
            setItem('cityArr', res.data);
          } else {
            message.warning(res.message);
          }
        })
      }
    }
    
    //设置城市数据为树状结构
    const setCityTreeNode = (arr) => {
      let CityAll = arr;
      var returnData = [];
      for(var i = 0; i < CityAll.length; i++) {
        if (CityAll[i].parentId === '0') {
          var tempObj = {
            title: CityAll[i].areaName,
            value: CityAll[i].id,
            checkable: false,
          }
          if (CityAll[i].id == '01' || CityAll[i].id == '02' ||  CityAll[i].id == '09' ||  CityAll[i].id == '22') {
            tempObj.checkable = true;
          }
          returnData.push(tempObj)
        }
      }
      for(var j = 0; j < returnData.length; j++) {
        var childArr = []
        for(var k = 0; k < CityAll.length; k++) {
          if (returnData[j].value === CityAll[k].parentId && 
            CityAll[k].parentId !== '01' && 
            CityAll[k].parentId !== '02' &&  
            CityAll[k].parentId !== '09' &&  
            CityAll[k].parentId !== '22') {
            var childTemObj = {
              title: CityAll[k].areaName,
              value: CityAll[k].id
            }
            childArr.push(childTemObj);
          }
        }
        if (childArr.length > 0) {
          returnData[j].children = [...childArr];
        }
      }
      data.treeData = [...returnData];
      if (citySel.value.length == props.selectNum) {
        getAllIds(data.treeData, citySel.value)
      }
    }
    
    //筛选操作
    const filterTreeNode = (inputValue, treeNode) => {
      return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1; 
    }

    //递归遍历数组
    const getAllIds = (tree,value)=>{
      for (const i in tree) {
        tree[i].disabled = value.length ?!(value.findIndex(info =>  tree[i].value == info.value) > -1):false
        if(tree[i].disabled && tree[i].value == value) {
          tree[i].disabled = false
        }
        if (tree[i].children) {
          getAllIds(tree[i].children,value);
        }
      }
    }
    //选中值发生改变
    const searchBtn = (value) => {
      if( value.length <= props.selectNum ){
        emit('searchCityhandle', value)
        getAllIds(data.treeData,[])
      }
      if(value.length == props.selectNum){
        getAllIds(data.treeData,value)
      }
    }
    //输入的搜索内容
    const searchValue = (value) => {
      data.selVal = value;
      setNodeBtn();
    }
    //设置按钮
    const setNodeBtn = () => {
      nextTick(() => {
        $(".btn-tree-buttom").remove();
        if ($(".btn-tree-buttom").length == 0) {
          var div = $("<div class='btn-tree-buttom'><a type='primary' class='mainBtn' @click='handleButtonClick'>确 定</a></div>")
          $(".ant-select-tree-list").append(div);
        }
        $(".ant-select-tree-list").on("click", ".mainBtn", () => {
          $(".ant-select-selection-search-input").blur();
          data.selVal = '';
        })
      })
    }
    //过滤2个数组的不同
    const fnDifference = (aLong, aShort, callback) => {
      return aLong.filter(fItem => {
        let nDifference = aShort.findIndex(item=>callback(fItem, item));
        return nDifference == -1
      })
    }
    return {
      ...toRefs(data),
      citySel,
      getCityAll,
      setNodeBtn,
      searchValue,
      setCityTreeNode,
      filterTreeNode,
      searchBtn,
      fnDifference,
      validater
    };
  },
});
</script>
<style>

</style>