<!--* 头像裁剪-->
<template>
  <div ref="avatarCropper" >
    <a-modal 
      :getContainer="() => $refs.avatarCropper"
      title="头像裁剪"
      :visible="uploadVisible"
      width="520px"
      centered
      :zIndex="2003"
      :maskStyle="{'z-index': '2002'}"
    >
      <template #closeIcon>
        <close-outlined @click.stop="closedDrawer"/>
      </template>
      <div class="avatar-container">
        <div class="avatar-left">
          <!-- 待上传图片 -->
          <div v-show="!options.img" class="avatar-left-crop1 avatar-left-crop">
            <a-upload
              v-model:file-list="uploadFlie"
              id="upload_file"
              class="avatar-uploader1"
              action="string"
              :http-request="uploadH"
              :before-upload="beforeAvatarUpload"
              :show-file-list="false"
              ref="upload"
            >
              <div class="add_upload">
                <svg
                  width="34px"
                  height="34px"
                  viewBox="0 0 34 34"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                >
                  <!-- Generator: Sketch 61.1 (89650) - https://sketch.com -->
                  <title>icon/tianjia</title>
                  <desc>Created with Sketch.</desc>
                  <g
                    id="海斗网1.0"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="选择候选人-单个录入简历-修改头像备份"
                      transform="translate(-235.000000, -277.000000)"
                      fill="#0063F5"
                      fill-rule="nonzero"
                    >
                      <g
                        id="编组-22"
                        transform="translate(65.000000, 93.000000)"
                      >
                        <g
                          id="编组-21"
                          transform="translate(36.000000, 93.000000)"
                        >
                          <g
                            id="编组-15"
                            transform="translate(48.000000, 91.000000)"
                          >
                            <g
                              id="icon/tianjia_export"
                              transform="translate(86.000000, 0.000000)"
                            >
                              <g
                                id="icon/tianjia"
                                transform="translate(1.000000, 1.000000)"
                              >
                                <path
                                  d="M27.3130305,4.68414302 C24.2911499,1.66361873 20.2747414,0 16.0014353,0 C11.7281292,0 7.71026845,1.66361873 4.68808394,4.6841091 C1.66589943,7.70459947 0,11.7248418 0,15.9998304 C0,20.274819 1.66515646,24.2942811 4.68811771,27.315857 C7.71107896,30.3374328 11.726677,32 16.0003208,32 C20.2739646,32 24.2911162,30.338213 27.3118823,27.3159248 C30.3326484,24.2936366 32,20.2737675 32,15.9987788 C32,11.7237902 30.3373088,7.7057867 27.3129292,4.68417694 L27.3130305,4.68414302 Z M24.0020009,17.1479526 L17.1444552,17.1479526 L17.1444552,24.0362445 C17.1444552,24.6702948 16.6327203,25.1843328 16.001469,25.1843328 C15.3702178,25.1843328 14.8584491,24.6702948 14.8584491,24.0362784 L14.8584491,17.1479186 L8.0005319,17.1479186 C7.36928066,17.1479186 6.8575457,16.6339146 6.8575457,15.9998304 C6.8575457,15.3657462 7.36928066,14.8517422 8.0005319,14.8517422 L14.8584153,14.8517422 L14.8584153,7.96375547 C14.8584153,7.32973913 15.3701503,6.81570116 16.0014015,6.81570116 C16.6327203,6.81570116 17.1444215,7.32973913 17.1444215,7.96378939 L17.1444215,14.8520814 L24.0019672,14.8520814 C24.6332184,14.8520814 25.1449534,15.3661193 25.1449534,16.0001357 C25.1449534,16.634152 24.6332184,17.1482578 24.0019672,17.1482578 L24.0019672,17.1479186 L24.0020009,17.1479526 Z"
                                  id="形状"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
                <a-button class="add_upload_p" ref="uploadBtn" type="link"> 添加图片</a-button>
                <p class="add_upload_txt">只支持jpg、png、jpeg，大小不超过5M</p>
                <p class="add_upload_txt">建议尺寸240x240</p>
              </div>
            </a-upload>
          </div>
          <!-- 已上传图片 -->
          <div v-show="options.img" class="avatar-left-crop">
            <vueCropper
              v-if="uploadVisible"
              class="crop-box"
              ref="cropperRef"
              :img="options.img"
              :autoCrop="options.autoCrop"
              :fixedBox="options.fixedBox"
              :canMoveBox="options.canMoveBox"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :centerBox="options.centerBox"
              :fixed="options.fixed"
              :fixedNumber="options.fixedNumber"
              :canMove="options.canMove"
              :canScale="options.canScale"
              :infoTrue="options.infoTrue"
              :info="options.info"
              :outputType="'png'"
              @realTime="realTime"
            >
            </vueCropper>
          </div>
          <div class="reupload" v-show="options.img">
            <!-- <div @click="reupload" class="reupload-txt">重新上传</div> -->
            <div class="icon-box">
              <plus-circle-outlined @click="changeScale(1)"/>
              <minus-circle-outlined  @click="changeScale(-1)"/>
              <redo-outlined @click="rotateRight"/>
            </div>
          </div>
        </div>
        <div class="avatar-right">
          <div v-if="previews.url" class="avatar-right-div">
            <div :style="{  transform:`scale(calc(100 / ${previews.w}),calc(100 / ${previews.h}))`}" style="transform-origin:0 0">
              <img :src="previews.url" :style="previews.img" />
            </div>
          </div>
          <img src="../../assets/images/header_pic@2x.png"  v-else/>
          <div class="avatar-right-text">头像预览</div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <a-button @click="closedDrawer" class="cancelBtn">取消</a-button>
          <a-button type="primary" @click="getCrop" class="mainBtn">确定</a-button>
        </div>
      </template>
    </a-modal >
  </div>
</template>
 
<script>
// import $ from "jquery";
import axios from "axios";
import {
  getOssPolicy
} from '@/api/comm'
import { message ,Upload } from "ant-design-vue";
import { defineComponent, reactive, toRefs ,ref} from "vue";
import VueCropper from "vue-cropper/src/vue-cropper.vue"
import {
  RedoOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
  name: "avatarCropper",
  components: {
    VueCropper,
    RedoOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    CloseOutlined,
  },
  props: {
    uploadVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits:['headUpload','closeCropper'],
  setup(props,{ emit }){
    var data =reactive({
      uploadBtn:{},//ref
      processLen:0,
      filename:'',
      uploadFlie:[],
      uploadType: 'head_upload',
      ossItem: {//签名
        accessId: "",
        policy: "",
        signature: "",
        dir: "",
        host: "", //oss服务器上传地址
        expire: "",
        callback: "",
        fileName: "", //文件名
      },
      
    })
    const cropperRef =  ref({})
    const previews=ref({})
    var options = reactive({
      img: "", // 原图文件
      fixedNumber :[1,1],// 截图框宽高比例
      autoCrop: true, // 默认生成截图框
      fixedBox: false, // 固定截图框大小
      canMoveBox: true, // 截图框可以拖动
      autoCropWidth: 100, // 截图框宽度
      autoCropHeight: 100, // 截图框高度
      fixed: true, // 截图框宽高固定比例
      centerBox: false, // 截图框被限制在图片里面
      canMove: true, // 上传图片允许拖动
      canScale: true, // 上传图片允许滚轮缩放
      info: false, // 裁剪框的大小信息
      infoTrue: true,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
    })
    // vueCropper组件 裁剪配置信息
    //实时预览数据
    const realTime = (data)=> {
      previews.value = data;
    }
    //向右旋转
    const rotateRight= ()=> {
      cropperRef.value.rotateRight()
    }
    // 1放大-1缩小
    const changeScale=(val) =>{
      cropperRef.value.changeScale(val);
    }
    //上传头像
    const uploadH=(params) =>{
      const formData = new FormData();
      formData.append("headPhoto", params.file);
    }
    const beforeAvatarUpload =(file) =>{
      data.filename = file.name;
      const isJPG = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        message.error("图片格式错误，请重新上传!");
        return false || Upload.LIST_IGNORE;
      }
      if (!isLt5M) {
        message.error("上传头像图片大小不能超过 5MB!");
        return false || Upload.LIST_IGNORE;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        options.img = e.target.result; // base64
        // // 创建p节点
        // var para = document.createElement("span");
        // var para1 = document.createElement("span");
        // // 查找div1
        // var box = document.getElementsByClassName("cropper-view-box")[0];
        // // 把p节点添加到div1里
        // box.appendChild(para);
        // box.appendChild(para1);
          //insertAfter(),将元素追加到指定对象的后面（是同级关系）                  
        // $("<span class='crop-point point8 point8_1'></span>").insertAfter($(".point8"));
        // $("<span class='crop-point point6 point6_1'></span>").insertAfter($(".point6"));
        // $("<span class='crop-point point3 point3_1'></span>").insertAfter($(".point3"));
        // $("<span class='crop-point point1 point1_1'></span>").insertAfter($(".point1"));
      };
    }
    const getCrop=()=>{
      if(!options.img ){
        message.warning('请先上传图片');
        return 
      }
      cropperRef.value.getCropBlob((options) => {
        // 将blob转换为file
        options.lastModifiedDate = new Date(); // 文件最后的修改日期
        let file = new File([options], data.filename, {
          type: options.type,
          lastModified: Date.now(),
        });
        let param1 = {
          fileName: data.filename
        }
        getOssPolicy(data.uploadType,param1).then((res) => {
          if (res.success) {
            data.ossItem = res.data;
            const formData = new FormData();
            formData.append("OSSAccessKeyId", data.ossItem.accessId);
            formData.append("success_action_status", '200');
            formData.append("policy", data.ossItem.policy);
            formData.append("signature", data.ossItem.signature);
            formData.append("key", data.ossItem.dir + "/" + data.ossItem.fileName);
            formData.append("callback", data.ossItem.callback);
            formData.append("file", file);
            axios({
              method: "post",
              url: data.ossItem.host,
              data: formData,
              onUploadProgress: (progressEvent) => {
                data.processLen = parseInt(
                  ((progressEvent.loaded / progressEvent.total) * 100) | 0,
                  10
                );
              },
            })
            .then((resp) => {
              emit("headUpload", resp.data.url);
              closedDrawer()
            })
            .catch((err) => {
              message.warning(err.data.message)
            });
          }else{
            message.warning(res.message)
          }
        })
        .catch((err) => {
          message.warning(err.data.message)
        });
      })
      
    }
    // 重新上传
    const uploadBtn=ref({})
    const reupload =()=> {
      uploadBtn.click();
    }
    // 关闭弹框
    const closedDrawer=()=> {
      emit("closeCropper", false);
      options.img = "";
    }

    return{
      ...toRefs(data),
      options,
      realTime,
      rotateRight,
      changeScale,
      uploadH,
      beforeAvatarUpload,
      getCrop,
      reupload,
      closedDrawer,
      uploadBtn,
      previews,
      cropperRef,
    }
  },
});
</script>
 
<style lang="less" scoped>
::v-deep * {
  touch-action: pan-y!important;
}
// 裁剪框样式
::v-deep .crop-point{
  border-radius: 0%!important;
  display: block!important;
  position:absolute!important;
  background:#0063f5!important;
  width: 4px!important;
  height: 8px!important;
  opacity: 1!important;
}
::v-deep .point8,::v-deep .point6,::v-deep .point3,::v-deep .point1{
  height: 18px!important;
}
::v-deep .point8_1,::v-deep .point6_1,::v-deep .point3_1,::v-deep .point1_1{
  width: 14px!important;
  height: 4px!important;
}
::v-deep .point1_1{
  top:-4px!important;
  left: 0px!important;
}
::v-deep .point3_1{
  top:-4px!important;
  right: 0px!important;
}
::v-deep .point8_1{
  bottom:-5px!important;
  right: 0px!important;
}
::v-deep .point6_1{
  bottom: -5px!important;
  left: 0px!important;
}
::v-deep .point2,::v-deep .point7{
  width: 8px!important;
  height: 4px!important;
}
::v-deep .point7{
  bottom: -3px!important;
}
::v-deep .point4{
  left: -3px!important;
}
::v-deep .point5 {
  right: -3px!important;
}
::v-deep .point2{
  top:-3px!important;
}
::v-deep .cropper-view-box {
  position: relative;
}
::v-deep .cropper-view-box > span {
  border: 0 dashed #fff;
  position: absolute;
  display: block;
  opacity: .5;
}
::v-deep .cropper-view-box>span:first-of-type {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: 33.33333%;
  left: 0;
  top: 33.33333%;
  width: 100%;
}
::v-deep .cropper-view-box>span:last-of-type{
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: 33.33333%;
  top: 0;
  width: 33.33333%;
}
.add_upload {
  width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.add_upload:hover {
  cursor: pointer;
}
.add_upload_p {
  font-size: 16px;
  font-weight: 400;
  color: #33383d;
}
.add_upload_txt {
  font-size: 14px;
  font-weight: 400;
  color: #969ba4;
}
::v-deep .cropper-dashed{
  border:0 dashed #f03a50
}
.reupload {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  cursor: pointer;
  .reupload-txt{
    color: #0063f5;
  }
  .icon-box{
    ::v-deep svg{
      margin-left: 16px;
      color: #bdbdbd;
    }
  }
}
::v-deep .reupload i{
  font-size:18px
}
.avatar-container {
  display: flex;
  .upload {
    text-align: center;
    margin-bottom: 24px;
  }
}
.avatar-left{
  margin-right: 38px;
}
.avatar-left-crop1 {
  background: #f5f6f8;
}
.avatar-left-crop {
  width: 320px;
  height: 320px;
  position: relative;
  .crop-box {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
.avatar-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar-right-div{
    width: 100px;
    height: 100px;
    border: 1px solid #dcdfe6;
    background: #F5F6F8;
    border-radius: 50%;
    overflow: hidden;
    // img{
    //   border-radius: 50%;
    // }
  }
  .avatar-right-text {
    margin-top: 24px;
    font-size: 16px;
    color: #33383d;
  }
  
}
img{
  width: 100px;
  height: 100px;
}
</style>

