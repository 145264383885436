//1920是涉及稿尺寸，可以改成自己的设计稿尺寸
//1rem=10px
(function (doc, win) {
  let fn = () => {
    let docEl = doc.documentElement,
    clientWidth = docEl.clientWidth;
  if (!clientWidth) return;
  docEl.style.fontSize = 10 * (clientWidth / 1920) + "px";
};
if (!doc.addEventListener) return;
fn();
win.addEventListener("resize", fn);
doc.addEventListener("DOMContentLoaded", fn);
})(document, window);