// 头像
export function setPhoto(photoUrl){
  if(!photoUrl || photoUrl== null ||photoUrl==undefined){
    return require('../assets/images/header_pic@2x.png')
  }else{
    return photoUrl
  }
}
//查询字典数据
import {dicList,fieldSettingsListApi} from '@/api/comm'
import { message } from 'ant-design-vue'
export function getDicList (type,isNull){
  let param= {
    dicBusinessType:type,
  }
  return dicList(param).then(async res => {
    if (res.success) {
      if(isNull ===0){
        res.data.unshift({ dicKey: '',dicValue: "请选择"})
      }
      return res.data
    } else {
      message.warning(res.message);
    }
  })
}
//查询字段设置
export function getFieldSet(type){
  let param= {
    module:type,
  }
  return fieldSettingsListApi(param).then(async res => {
    if (res.success) {
      return res.data
    } else {
      message.warning(res.message);
    }
  })
}
//推荐状态字典状态
export function getRecommendStatus(status) {
  switch(status) {
    case 100:
      return '加入项目';
    case 200:
    case 300:
      return '推荐简历';
    case 400:
      return '预约面试';
    case 500:
      return '客户面试';
    case 600:
      return '确认offer';
    case 700:
      return '成功入职';
    case 900:
      return '人选离职';
    case 997:
      return '客户否决';
    case 998:
      return '顾问否决';
    case 999:
      return '人选放弃';  
  }
}
//获取字典对应的value值
export function getDicValue(arr,dicKey){
  var value;
  arr.find(item => {
    if(item.dicKey == dicKey){
      value = item.dicValue
    }
  })
  return value
}
//职位-根据下拉框的值匹配文本
export function getValueToTxt(arr, dicKey){
  var txt;
  arr.find(item => {
    if(item.value == dicKey){
      txt = item.label
    }
  })
  return txt;
}
//推荐-展示中、英文字符
export function getEnNameAndCnName(cnName, enName){
  return cnName+'（'+enName+'）';
}

//获取用户信息
import {
  getUserInfoApi,//获取当前登录人信息
} from "@/api/comm"
export async function  getUserInfo(){
 await getUserInfoApi().then(res => {
    if (res.success) {
      localStorage.setItem('USER',JSON.stringify(res.data))
      // $bus.emit("changeUSER", true);
    } else {
      message.warning(res.message)
    }
  })
}

//统计列表时间转换
import dayjs from 'dayjs'
let quarterOfYear = require('dayjs/plugin/quarterOfYear')//day.js季度插件
dayjs.extend(quarterOfYear)
export function quickTime(key) {
  let start = ''
  let end = ''
  switch (key) {
    case '本年':
      start = dayjs().startOf('year').format('YYYY-MM');
      end = dayjs().endOf('year').format('YYYY-MM');
      break;
    case '上年':
      start = dayjs().add(-1, 'year').startOf('year').format('YYYY-MM');
      end = dayjs().add(-1, 'year').endOf('year').format('YYYY-MM');
      break;
    case '上上年':
      start = dayjs().add(-2, 'year').startOf('year').format('YYYY-MM');
      end = dayjs().add(-2, 'year').endOf('year').format('YYYY-MM');
      break;
    case '本季':
      start = dayjs().startOf('quarter').format('YYYY-MM');
      end = dayjs().endOf('quarter').format('YYYY-MM');
      break;
    case '上季':
      start = dayjs().add(-1, 'quarter').startOf('quarter').format('YYYY-MM');
      end = dayjs().add(-1, 'quarter').endOf('quarter').format('YYYY-MM');
      break;
    case '上上季':
      start = dayjs().add(-2, 'quarter').startOf('quarter').format('YYYY-MM');
      end = dayjs().add(-2, 'quarter').endOf('quarter').format('YYYY-MM');
      break;
    case '本月':
      start = dayjs().startOf('month').format('YYYY-MM');
      end = dayjs().endOf('month').format('YYYY-MM');
      break;
    case '上月':
      start = dayjs().add(-1, 'month').startOf('month').format('YYYY-MM');
      end = dayjs().add(-1, 'month').endOf('month').format('YYYY-MM');
      break;
    case '上上月':
      start = dayjs().add(-2, 'month').startOf('month').format('YYYY-MM');
      end = dayjs().add(-2, 'month').endOf('month').format('YYYY-MM');
      break;
  }
  return [start, end];
}

//客户状态
export function custormStatus(flg){
  let obj={};
  switch (flg) {
    case 0:
      obj.color = "icon_blue";
      obj.txt = "开发中客户";
      break;
    case 1:
      obj.color = "icon_green";
      obj.txt = "已签约客户";
      break;
    case 2:
      obj.color = "icon_gray";
      obj.txt = "签约到期";
      break;
    case 4:
      obj.color = "icon_red";
      obj.txt = "终止合作";
      break;
    default:
      break;
  }
  return obj;
}

//用户下拉框
import {
  userSearchApi, //查询公司所有用户
} from '@/api/comm'
export function getParticipantName(path){
  let param = {
    searchCondition: '',
  }
  return userSearchApi(path,param).then(res => {
    if (res.success) {
      return res.data;
    } else {
      message.warning(res.message);
    }
  })
}

//校验简历是否可以被查看
import {resumeCheckValidApi} from '@/api/resume'
export function resumeIsSee(resumeId) {
  let param = {
    resumeId:resumeId,
  }
  return resumeCheckValidApi(param).then(res=>{
    if (res.success) {
      if(!res.data) message.warning('该简历已被删除,无法查看');
      return res.data
    }
  })
}