import { createApp } from 'vue'
import App from './App.vue'
import { componentsPlugin } from './plugins/antd'
import router from './router/index'
import 'ant-design-vue/dist/antd.less'
import './utils/rem'
import { message } from 'ant-design-vue'
import echarts from './utils/echarts';
import { resumeIsSee } from "@/composables/base"

// 使用$bus事件总线
import mitt from "mitt"
const $bus = {}
const emitter = mitt()
$bus.on = emitter.on
$bus.emit = emitter.emit
$bus.off = emitter.off

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0,0);//每次跳转页面回到页面顶部
  let token = localStorage.getItem('TOKEN') && localStorage.getItem('USER');
  let users = localStorage.getItem('USER')?JSON.parse(localStorage.getItem("USER")):{}
  if (to.path == '/login') {
    if (token) {
      next('/')
    } else {
      next()
    }
  } else if (!token && to.path !== '/login') {
    next('/login');
  } else if(((to.path == '/personnelSet' ||
    to.path == '/custormSet'||
    to.path == '/positionSet' ||
    to.path == '/logoSet' ||
    to.path == '/jobSet' ||
    to.path == '/companyList') && 
    users.role !== 'GROUP_COMPANY_ADMIN_ROLE') ||
    (to.path == '/userList' && users.role == 'NORMAL_ROLE')){
    next('/homePage')
  } else if(to.path == '/personnelDetails' && !await resumeIsSee(to.query.id)){
    if(from.path == '/'){
      setTimeout(()=>{
        window.close()
      },500)
    }else{
      next(from.path)
    }
  }
  else{
    next()
  }
})

const app = createApp(App)
app.config.warnHandler = () => null
app.config.globalProperties.$echarts = echarts
app.config.$message = message
message.config({
  duration: 2, //持续时间
  maxCount: 1
})
componentsPlugin(app)
app.use(router)
// app.use(Antd)
app.config.globalProperties.$bus = $bus
app.mount('#app')
// app.component('QuillEditor', QuillEditor)
